/* eslint-disable max-len */

export default {
    header: 'Оформление страхования',
    homeHeader: 'Спокойствие за рулём',
    headStep1: 'Данные',
    headStep2: 'Программы',
    headStep3: 'Опции',
    headStep4: 'Оформление',
    headStep5: 'Оплата',
    carMark: 'Марка',
    carModel: 'Модель',
    carIssueYear: 'Год выпуска',
    carBodyType: 'Тип кузова',
    carBodyTypeLabel: {
        0: 'Седан',
        1: 'Хэтчбек',
        2: 'Универсал',
        3: 'Внедорожник',
        4: 'Кроссовер',
        5: 'Пикап',
        6: 'Минивен',
        7: 'Фургон',
        8: 'Кабриолет',
        9: 'Купе',
    },
    policeSumInsured: 'Страховая сумма',
    calculateInstallments: 'Рассчитать рассрочку',
    insuranceCost: 'Предварительный расчет:',
    openInfoPopup: 'Что входит в полис?',
    inInstallments: 'В рассрочку',
    toCar: 'На автомобиль',
    toCarYear: 'г.в.',
    onMonth: 'TJS в месяц',
    promoCode: 'Промокод',
    errorCoupon: 'Ошибка ввода промокода',
    coupon: 'Введите промокод',
    nextStep: 'Далее',
    beginDate: 'Дата начала',
    automobile: 'Автомобиль',
    insured: 'Страхователь',
    holderFullName: 'Фамилия, Имя, Отчество',
    holderFullNamePH: 'Алексей Петрович Иванов',
    holderAddress: 'Адрес проживания',
    holderAddressPH: 'Адрес проживания',
    holderPhone: 'Телефон',
    holderEmail: 'Электронная почта',
    drivers: 'Водители',
    multidrive: 'Мультидрайв',
    copyHolderData: 'Скопировать информацию о страхователе',
    addDriver: 'Добавить водителя',
    deleteDriver: 'Удалить водителя',
    vin: 'VIN',
    color: 'Цвет',
    gosNomer: 'Гос номер',
    bodyNumber: 'Номер кузова',
    registrSvidet: 'Свидетельство о регистрации',
    holderDocType: 'Тип документа',
    holderDocSeries: 'Серия паспорта',
    holderDocNumber: 'Номер паспорта',
    driverDocSeries: 'Серия ВУ',
    driverDocNumber: 'Номер ВУ',
    driverIssueDate: 'Дата выдачи ВУ',
    holderIssueDate: 'Дата выдачи паспорта',
    citizenship: 'Гражданство',
    holderVuSeries: 'Серия ВУ',
    holderVuNumber: 'Номер ВУ',
    holderVuIssueDate: 'Дата выдачи ВУ',
    holderBirthday: 'Дата рождения',
    holderGender: 'Пол',
    changeInfo: 'Изменить',
    beginDateIssued: 'Дата начала страхования',
    durationIssued: '{{duration}} дней',
    holderAgree: 'К оформлению',
    driving_interval: '(0)[{{count}} лет];(1)[{{count}} год];(2-4)[{{count}} года];(5-inf)[{{count}} лет];',
    additionalOptions: 'Выбранные опции',
    program: 'Выбранная программа',
    reqFieldsHead: 'Заполните',
    reqFieldsText: 'обязательные поля',
    damageOptionHint: 'БИМА возместит ущерб, в результате повреждения или гибели ТС, его отдельных частей, агрегатов, узлов в следствии пожара, взрыва, природных явлений, падения или попадания инородных предметов или повреждения животными.',
    stealingOptionHint: 'БИМА компенсирует затраты, при повреждении или утрате автомобиля в результате хищения (кражи, грабежа, разбоя).',
    tfapOptionHint: 'БИМА компенсирует расходы, возникшие при повреждении ТС или гибели ТС в результате противоправных действий третьих лиц и рисков утраты отдельных частей, агрегатов, узлов ТС в результате их хищения.',
    noTraficPoliceOptionHint: 'БИМА возместит расходы, которые понадобятся на восстановление автомобиля, пострадавшего в ДТП без справок ГАИ.',
    accSumAssuredHint: 'БИМА возместит материальный ущерб, в результате получения травм, постоянной утраты трудоспособности (инвалидность) или смерти пассажиров и/или водителя застрахованного автомобиля.',
    annually: 'Один раз',
    monthly: 'Ежемесячно',
    SCI_P1name: 'Стандарт',
    SCI_P2name: 'Комфорт',
    SCI_P3name: 'Премиум',
    SCI_P4name: 'Индивидуальная',
    SCI_P1: 'Стандарт',
    SCI_P2: 'Комфорт',
    SCI_P3: 'Премиум',
    SCI_P4: 'Индивидуальная',
    SCI_P1description: 'Дорожная ситуация всегда меняется, даже если вы - опытный водитель!<br>По программе "Стандарт" вы не будете платить за ремонт чужого автомобиля, пострадавшего в ДТП по вашей вине. Все расходы покроет ваша страховка.',
    SCI_P2description: 'Непредвиденные события на дороге случаются постоянно!<br>Программа "Комфорт" - это финансовая "подушка безопасности" при ДТП по вашей вине, которая обеспечит восстановление вашего и пострадавшего автомобиля.',
    SCI_P3description: 'Непредсказуемые происшествия на дороге - обычное дело!<br>По программе "Премиум", независимо от того, по чьей вине произошло ДТП - вашей или другого водителя, ремонт автомобилей будет возмещен страховой компанией.',
    SCI_P4description: 'На дороге опасности подвержен каждый участник – водители, пассажиры, пешеходы! <br /> «Индивидуальную» Программу Вы формируете по своему желанию, выбирая весь перечень услуг или подбирая для себя необходимые опции',
    programOption1: 'Гражданская ответственность',
    programOption2: 'ДТП по вине водителя',
    programOption3: 'ДТП не по вине водителя',
    categories: 'Категории',
    categories_name: [
        'Вам должно быть не менее 21 года.',
        'Минимальный стаж вождения должен составлять не менее 1 года.',
        'Мы страхуем автомобили, которые не старше 20 лет.',
        'Не предоставляем страхование для такси и юридических лиц.',
        'Каждая программа страхования предполагает одну выплату.',
        'После первой выплаты полис прекращает действовать только для той программы, по которой произошла выплата. <br />Полис по другим программам страхования остается в силе.',
    ],
    taxi: 'Нельзя страховать такси',
    autoText: 'ТС нет в списке',
    fio: 'Ф.И.О.',
    driverSeriesNumber: 'Серия и номер',
    driverPeriod: 'Стаж вождения',
    noVin: 'При отсутствии VIN, заполнить только поле Номер кузова.',
    ECI: 'Выберите программу',
    ECI_P1: 'Полное каско',
    ECI_P2: 'Частичное каско',
    ECI_P3: 'Угон + полная гибель',
    ECI_P1_hint: 'Опции: «ДТП по вине водителя», «ДТП по вине 3-го лица», «Новое за старое», «Компенсация расходов», «Без справок»',
    ECI_P2_hint: 'Опции: «ДТП по вине 3-го лица», «Новое за старое», «Оценка Страховщика», «Без справок»',
    ECI_P3_hint: 'Опции: «ДТП по вине водителя», «ДТП по вине 3-го лица», «Старое за старое» Если возраст ТС больше 20, но меньше 30 лет включительно',
    crSumAssured: 'Гражданская ответственность',
    incidentSumAssured: 'Несчастный случай',
    crSumAssuredHint: 'Гражданская ответственность — это обязанность по возмещению ущерба третьим лицам, возникшего по вине страхователя.',
    incidentSumAssuredHint: 'Несчастный случай – внезапное, кратковременное, непредвиденное, непреднамеренное внешнее событие, характер, время и место которого могут быть однозначно определены, и которое повлекло за собой телесное повреждение, или иное нарушение функций организма, или смерть Застрахованного лица, не являющееся следствием заболевания, произошедшее в течение срока страхования независимо от воли Застрахованного, и/или Страхователя, и/или',
    optionsMore: 'Дополнительные опции',
    insuranceCompensationTypes: 'Виды страхового возмещения',
    compensation0: 'Компенсация расходов',
    compensation0Hint: 'Страхователем осуществляется ремонт поврежденного ТС. Сумма возмещения определяется на основании предоставленных документов, с учетом условий договора страхования. Оплата производится Страховщиком в пользу Страхователя или Выгодоприобретателя.',
    compensation1: 'Независимая экспертиза',
    compensation1Hint: 'Страховая компания проводит независимую экспертизу для расчета стоимости ремонтных работ по поврежденному транспортному средству. С согласия Страховщика или по его инициативе, проводится экспертиза, результаты которой определяют сумму страхового возмещения. Эта сумма включает стоимость экспертизы и устанавливается в соответствии с условиями договора страхования и Полисных условий.',
    compensation2: 'Оценка страховщика',
    compensation2Hint: 'Расчет стоимости ремонтно-восстановительных работ поврежденного ТС осуществляется экспертами Страховщика. Сумма страхового возмещения определяется на основании результатов данной оценки с учетом условий договора страхования и Полисных условий. Оплата денежных средств (страхового возмещения) производится Страховщиком (наличным и/или безналичным путем) в пользу Страхователя или Выгодоприобретателя',
    compensation3: 'СТОА Страховщика',
    compensation3Hint: 'Организация ремонтно-восстановительных работ поврежденного ТС осуществляется Страховщиком в СТОА, состоящей с ним в договорных отношениях. Оплата денежных средств (страхового возмещения) производится Страховщиком (наличным и/или безналичным путем) в пользу СТОА, которая осуществила (осуществляет или будет осуществлять) ремонт поврежденного ТС',
    programsMore: 'Дополнительные программы',
    compensation: 'Размер возмещения',
    accidentAnyOption: 'ДТП по вине водителя',
    accidentAnyOptionHint: 'Дорожно-транспортное происшествие, которое произошло по вине водителя застрахованного транспортного средства (наезд, столкновение и т. д.)',
    accident3tpOption: 'ДТП по вине третьих лиц',
    accident3tpOptionHint: 'ДТП с участием застрахованного ТС в результате наезда, опрокидывания, столкновения и других действий третьих лиц, включая повреждение на стоянке другими автомобилями',
    oldNewOption: 'Новое за старое',
    oldNewOptionHint: 'Возмещение заменяемых в ходе ремонта пострадавшего ТС запасных частей и материалов производится без учета износа, то есть в размере реального ущерба',
    noTraficPoliceSumAssured: 'Без участия ГАИ',
    noTraficPoliceHint: 'Рассмотрение страхового случая без участия ГАИ до: 1 000 сомони',
    damageCarWashSumAssured: 'Ущерб на мойке',
    damageCarWashHint: 'Вы имеете право получить страховое возмещение до определенного предела (до 1000 сомони), если сотрудники автомойки совершили действия, которые противоречат закону, без необходимости обращаться в специальные органы.',
    greenCard: 'Зеленая карта',
    greenCardHint: 'Расширение территории страхования ТС на другие страны',
    greenCardUzbOption: 'Узбекистан',
    greenCardKazOption: 'Казахстан',
    greenCardKgzOption: 'Киргизия',
    insuranceAreaExpansion: 'Расширение территории страхования',
    amountCompensation: 'Размер возмещения',
    payDateNow: 'Сегодня',
    payDateTo: 'До {{to}}',
}
