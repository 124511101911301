import React from 'react'
import { NavLink } from 'react-router-dom'
import { useTranslation } from 'react-i18next'
import { useModal } from '../../hooks/modal'
import BackCall from '../modals/BackCall'
import SwiperSlider from '../common/SwiperSlider'

function BoxesProductsUr({ products }) {
    const { t } = useTranslation('home')
    const [showBackCall] = useModal(
        <BackCall className="popup__policy-check" />,
    )
    if (!products) return null

    const slides = products.reduce((result, item) => {
        const slide = (
            <div className="main-popular__item">
                {/* <span className="main-popular__label">{t('corporate')}</span> */}
                <div className="main-popular__image main-popular__image_vertical">
                    <img
                        className="main-popular__img main-popular__img_vertical"
                        src={item.mainImg}
                        alt="" />
                </div>
                <div className="main-popular__info main-popular__info_vertical">
                    <h3 className="main-popular__subtitle ur-mobile">{item.title}</h3>
                    <span
                        className="main-popular__text">
                        {item.shortDesc}
                    </span>
                    <div className="main-popular__links">
                        <button className="offices__button ur-button"
                            onClick={() => {
                                showBackCall({
                                    note: item.name,
                                })
                            }}
                            type="button">
                            {t('request')}
                        </button>
                        <NavLink to={`/boxes/${item.slug}`}
                            className="slider-products__link info-link ur-button">
                            {t('more')}
                        </NavLink>
                    </div>
                </div>
            </div>
        )
        if (!item.seo) result.push(slide)
        return result
    }, [])

    return (
        <div className="boxes__container">
            <div className="boxes__products boxes_desktop">
                {
                    products.map((product) => {
                        if (!product.id) return null

                        return (
                            <div className="slider-products__item">

                                <div className="slider-products__image">
                                    <img src={product.mainImg} alt=""
                                        className="slider-products__img" />
                                </div>
                                <div className="slider-products__info">
                                    <h3 className="slider-products__subtitle1">{product.title}</h3>
                                    <p className="slider-products__text"
                                        dangerouslySetInnerHTML={{ __html: product.shortDesc }} />
                                    <div className="slider-products__links">
                                        <button className="offices__button ur-button"
                                            onClick={() => {
                                                showBackCall({
                                                    note: product.name,
                                                })
                                            }}
                                            type="button">
                                            {t('request')}
                                        </button>
                                        <NavLink to={`/jur/product/${product.slug}`}
                                            className="slider-products__link info-link">
                                            {t('more')}
                                        </NavLink>
                                    </div>
                                </div>
                            </div>
                        )
                    })
                }
            </div>
            <div className="boxes__ur">
                <SwiperSlider
                    className="main-popular-mobile"
                    loop items={slides}
                    centeredSlides
                    cssMode mousewheel
                />
            </div>
        </div>
    )
}

export default BoxesProductsUr
